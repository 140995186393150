:root {
  --color-primary-light: #398bef;
  --color-primary-main: #1375ec;
  --color-primary-dark: #126bd9;
  --color-primary-contrast-text: #ffffff;

  --color-secondary-light: #f6cf5a;
  --color-secondary-main: #dfaa0c;
  --color-secondary-dark: #b88d0a;
  --color-secondary-contrast-text: #ffffff;

  --color-error-light: #f56668;
  --color-error-main: #f0191c;
  --color-error-dark: #c00c0f;
  --color-error-contrast-text: #ffffff;

  --color-text: #000;
  --color-text-hightlight: #3d7fcf;
}
